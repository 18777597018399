<div class="">
    <div class="agent-position">
    <div class="agent p-3 d-flex justify-content-between align-items-center">
        <div class="semi-bold add-agents-content justify-content-center">Reset Your password</div>
        <div class="" (click)="closePasswordPopup()" *ngIf="hideCloseIcon == 'show'">
            <img src="assets/icons/close.png" class="close-icon" alt="close-icon" role="button">
        </div>

    </div>
    <form class="mt-2 p-3" [formGroup]="changePasswordForm" (ngSubmit)="confirmPassword()">
        <div class="user-feild">
            <label class="light">Current password</label>
            <div class="">
              <div class="position-relative">
                <input  [type]="show?'text':'password'"  class=" w-100 p-1"  formControlName="current_password"  [ngClass]="{ 'is-invalid': submitted && formControls['current_password'].errors }">
                <span >

                  <img src="assets/icons/password-show.svg" (click)="show=!show" aria-hidden="true" *ngIf="show" class="inputbox">
                  <img src="assets/icons/password-hide.svg"  (click)="show=!show" aria-hidden="true" *ngIf="!show" class="inputbox">
                </span>


              </div>
              <div *ngIf="submitted && formControls['current_password'].errors" >
                <div class="invalid-feedback d-block" *ngIf="formControls['current_password'].errors['required']">
                  Current password is required</div>
              </div>


            </div>
        </div>
        <div class="password-feild mt-3">
            <label class="light">New password</label>
            <div class="">
              <div class="position-relative">
              <input [type]="newPasswordShow?'text':'password'" class="PasswordTextbox w-100 p-1"  formControlName="new_password"  [ngClass]="{ 'is-invalid': submitted && formControls['new_password'].errors }" (change)="validateNewPassword()">
              <span>
              <img src="assets/icons/password-show.svg" (click)="newPasswordShow=!newPasswordShow" aria-hidden="true" *ngIf="newPasswordShow" class="confirmPassword">
                <img src="assets/icons/password-hide.svg"  (click)="newPasswordShow=!newPasswordShow" aria-hidden="true" *ngIf="!newPasswordShow" class="confirmPassword">
              </span>
              </div>
                  <div class="invalid-feedback d-block" *ngIf="formControls['new_password'].touched && formControls['new_password'].errors?.['pattern']">
                    New password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.
                  </div>
                  <div class="invalid-feedback d-block" *ngIf="formControls['new_password'].touched && formControls['new_password'].errors?.['compareOldNew']">
                    New password must be different from current password
                  </div>
                  <div *ngIf="submitted && formControls['new_password'].errors">
                    <div class="invalid-feedback d-block" *ngIf="formControls['new_password'].errors['required']" >
                      New password is required</div>
                  </div>
            </div>
        </div>

        <div class="password-feild mt-3">
          <label class="light">Confirm password</label>
          <div class="">
            <div class="position-relative">
            <input [type]="confirmPasswordShow?'text':'password'" class="PasswordTextbox w-100 p-1"  formControlName="confirm_password"  [ngClass]="{ 'is-invalid': submitted && formControls['confirm_password'].errors }" (change)="validateConfirmPassword()">
            <span>
            <img src="assets/icons/password-show.svg" (click)="confirmPasswordShow=!confirmPasswordShow" aria-hidden="true" *ngIf="confirmPasswordShow" class="confirmPassword">
              <img src="assets/icons/password-hide.svg"  (click)="confirmPasswordShow=!confirmPasswordShow" aria-hidden="true" *ngIf="!confirmPasswordShow" class="confirmPassword">
            </span>



            </div>
            <div class="invalid-feedback d-block" *ngIf="formControls['confirm_password'].touched && formControls['confirm_password'].errors?.['match']">
              Password does not match
            </div>

            <div *ngIf="submitted && formControls['confirm_password'].errors" >
              <div class="invalid-feedback d-block" *ngIf="formControls['confirm_password'].errors['required']" >
                Confirm password is required</div>
            </div>
            <div *ngIf="submitted && matchPassword && !formControls['confirm_password'].touched">
              <div class="invalid-feedback d-block">
                Password not matched
              </div>
            </div>
          </div>
      </div>




        <div class="login-button-parent mt-5">
            <div class="resetPassword text-center" >
                <button class="reset-password medium px-5 py-2" type="submit">Confirm</button>
            </div>
        </div>
        </form>
</div>
</div>
