<div>
  <div class="mt-5 text-center bold">Forgot Password</div>

  <div class="mt-4 medium text-center message-text">
    Enter your email below and we will send you new password to reset password
  </div>

  <form class="mt-5" [formGroup]="forgotPasswordForm" (ngSubmit)="submitPassword()">
    <div class="user-feild">
      <label class="medium">Email Id</label>
      <div>
          <input type="text" class="userIdTextbox w-100 p-1" formControlName="email_id" [ngClass]="{ 'is-invalid': submitted && formControls['email_id']['errors']}">
          <div *ngIf="submitted && formControls['email_id']['errors']" class="invalid-feedback">
              <div class="invalid-feedback d-block" *ngIf="formControls['email_id']['errors']['required']">
                Email is required</div>
                <div class="invalid-feedback d-block"*ngIf="formControls['email_id']['errors']['email']">Invalid email address</div>
            </div>
      </div>
  </div>
  <div class="mt-5">
    <button type="submit" class="w-100 text-center p-2 text-white bg-primary fs-6 rounded-4 border-0 regular">Submit</button>
  </div>

  <div class="mt-3">
    <button type="button" class="w-100 text-center p-2 text-primary fs-6 border-0 text-decoration-underline medium" (click)="passwordEmit()">Back to login</button>
  </div>
    </form>
  </div>
