import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api-service/api.service';
import { AuthServiceService } from 'src/app/authentication/auth-service/auth-service.service';
import { API_ENDPOINTS } from 'src/app/insurer-v1/constant/api.constant';
import { SessionService } from 'src/app/session-service/session.service';
import { API_ENDPOINTS_DISTRIBUTOR } from 'src/assets/data-variable/api-constant';
import { routePathForPeakre } from 'src/assets/data-variable/mock-data';
import { TOKEN } from 'src/assets/data-variable/session-variables';

@Component({
  selector: 'app-reinsurer-login',
  templateUrl: './reinsurer-login.component.html',
  styleUrls: ['./reinsurer-login.component.scss']
})
export class ReinsurerLoginComponent implements OnInit {
  templateName: any = 'signIn';
  logo: any;
  loginForm: FormGroup;
  submitted = false;
  show = false;
  reinsurerName = '';
  insurerName: any;
  enableNotFound: boolean = false;
  errorLogin: boolean = false;

  captchaCode: string;
  userResponse: string = '';
  failed_count = 0;
  validateCaptch: boolean = false;

  request = "welcome";

  constructor(private spinner: NgxSpinnerService, private toaster: ToastrService, private apiService: ApiService,
    private router: Router, private sessionService: SessionService, public fb: FormBuilder, private authService: AuthServiceService, private activateRoute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.loginForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]],
      password: ['', [Validators.required]],
      captchaText: [''],

    });

    this.activateRoute.params.subscribe(params => {
      this.reinsurerName = params?.['reinsurer'] || '';
      this.insurerName = params?.['insurer'] || '';
    });

    if(this.reinsurerName == 'insurancehouse' || this.reinsurerName == 'aiaw' || this.reinsurerName == 'abnic'  || this.reinsurerName == 'dnic' || this.reinsurerName == 'newton' || this.reinsurerName == 'axa-demo'|| this.reinsurerName == 'qic' || this.reinsurerName == 'takafulemarat' || this.reinsurerName == 'orienttakaful' || this.reinsurerName =='orientinsurance' || this.reinsurerName =='dni'|| this.reinsurerName =='dic' || this.reinsurerName =='rak'|| this.reinsurerName =='nlgi'|| this.reinsurerName =='orient' || this.reinsurerName =='awnic'  || this.reinsurerName =='nascore-insurer' || this.reinsurerName =='dohatakaful'||this.reinsurerName== 'medgulf-insurer' || this.reinsurerName== 'staging-test'|| this.reinsurerName == 'axa-dni' || this.reinsurerName == 'watania-takaful'|| this.reinsurerName == 'fidelity'|| this.reinsurerName == 'swissre' || this.reinsurerName == 'enaya'){
      this.router.navigateByUrl(`${routePathForPeakre[this.reinsurerName]}/${this.reinsurerName}/login`);
      return
    }
    this.sessionService.set('reinsurerShortForm', this.reinsurerName);
    await this.verifyPath();
  }

  get formControls() {
    return this.loginForm.controls
  }

  async verifyPath() {
    try {
      let params = {
        reinsurerCode: this.reinsurerName,
        insurerName: this.insurerName
      }
      let response = await this.apiService.postApiCall(`auth/${API_ENDPOINTS.checkInsurerAndReinsurePath}`, params);

      if (response) {
        this.logo = response?.response[0]?.logo;
        this.enableNotFound = false;
        this.sessionService.set("insurerURL", this.insurerName);
        this.sessionService.set("reinsurerName", this.reinsurerName);
        this.sessionService.set('logo', response?.response[0]?.logo)
      }
    } catch (er) {
      if (er.response.data.statusCode == 404) {
        this.enableNotFound = true;
      }
    }
  }

  async login() {
    try {
      this.spinner.show()
      this.submitted = true;
      let captchaValid = false;
      if (this.validateCaptch) {
        captchaValid = await this.apiService.validateCaptcha(this.captchaCode, this.loginForm);
        console.log(captchaValid)
        if (!captchaValid) {
          this.captchaCode = await this.apiService.generateCaptcha(5); // Generate a new captcha
          this.toaster.error("Invalid captcha. Please try again.");
          return
        }
      }
      if (!this.loginForm.valid) {
        return;
      }
      let params = {
        email: this.loginForm.value['email_id'],
        password: this.loginForm.value['password'],
        reInsurerCode: this.reinsurerName
      }
      // this.loginForm.value['reinsurerName'] = this.reinsurerName;
      this.loginForm.value['reInsurerCode'] = this.reinsurerName;
      const cipherText = this.apiService.encryptUsingAES256(JSON.stringify(this.loginForm.value));
      let results: any = await this.authService.login({ credentials: cipherText });
      if (results.statusCode == 200) {
        this.failed_count = 0;
      } if (!results) {
        this.toaster.error("Something went wrong");
        return
      }

      this.sessionService.set(TOKEN, results.data.details.token)
      this.sessionService.set("role", results.data.details.role)
      this.sessionService.set("user_id", results.data.details.userId)
      this.sessionService.set("insurerURL", results.data.details.company)
      this.sessionService.set("username", results.data.details.user_name)
      this.sessionService.set("insurerCompanyId", results.data.details.insurerCompanyId)
      
      this.sessionService.set("brokerCompanyId", "")
      this.sessionService.set('reinsurerName', results.data.details.reInsurerUrl);
      this.sessionService.set('reinsurerShortForm', results.data.details.insurerShortForm);
      if (results.data.details.passwordExpiry && results.response.reInsurerPasswordEnabledStatus) {
        this.sessionService.set("passwordExpiry", results.data.details.passwordExpiry)
        this.router.navigateByUrl(`/auth/reset-password`);
        return;
      };
      if (results.data.details.role == 'distributor') {
        this.router.navigateByUrl(`/${results.data.details.company}/distributor-v1/main`)
      } else if (results.data.details.role == 'customer') {
        this.router.navigateByUrl(`/${results.data.details.company}/customer-v1/home`)
      } else if (results.data.details.role == 'reinsurer') {
        const permissionsResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_DISTRIBUTOR.getAllPermissions}/${results.data.details.role}`, {});
        this.sessionService.set('permissions', JSON.stringify(permissionsResponse.response));
        this.router.navigateByUrl(`/${results.data.details.company}/reinsurer-v1`)
      }
      else if (results.data.details.role == 'insurer') {
        this.router.navigateByUrl(`/${results.data.details.company}/insurer-v1/dashboard`)
      }


      this.toaster.success("Logged in successfully");

    } catch (error) {
      if (error.response.data["statusCode"] == 403) {
        this.errorLogin = true
        this.failed_count += 1;
        if (this.failed_count >= 3) {
          this.validateCaptch = true;
          this.captchaCode = await this.apiService.generateCaptcha(5);

        }
      }
      // else if (error.response.status == 401) {
      //   this.toaster.error(error.response.data.message)
      //   return
      // }
    } finally {
      this.spinner.hide()
    }
  }


  async openForgotPasswordTemplates() {
    this.templateName = 'forgotPassword';
  }
  async passwordUpdatedNotifications(event) {
    this.templateName = 'signIn';
  }
}
