export const API_ENDPOINTS_DISTRIBUTOR = {
  getNextPossibleFlows: "next-flow",
  getCensusDetailsBasedOnCRN: "census-details",
  uploadMafOrGroupDocuments: "upload-maf-group-documents",
  getQuoteIdsBasedOnCRN: "quote-id-by-crn",
  updateQuoteWorkFlowStatus: "quote-workflow",
  getWorkFlowHistrory:'workflow-history',
  uploadChatDocuments:'quote-workflow',
  downloadDocuments:'download_documents',
  reviseQuote :'quote-revision',
  getRevisionQuotes:'quotes',
  getQuoteDetailsForSummarry:'get_quote_details',
  getAllInsurerList:'get_all_insurer_list',
  updateInsurerName:'update_insurer_name',
  userCompanyApis:'broker-company',
  getEmirates:'emirates',
  getTpa:'tpa',
  getPlans:'emirates_plans',
  userCompanyAgentsApis:'broker-agent',
  insurerBasedLogin:'auth/insurer/login',
  getDistributorCommission:'distributor-commission',
  chat:'chat',
  getCurrentFlow:'last-workflow',
  getAllTpaForUploadNetworkList:'get_all_tpa',
  networkListBasedOnTPA:'tpa_network_list',
  getUserBasedTpaList:'get_user_tpa',
  exclusionListApi:'exclusion-list',
  getAllQuotesForExport:'get-all-quotes-for-export',
  mafGroupDocuments:'get-maf-group-document',
  getAllQuotesHistoryUsingCRN:'quotes-history',
  getCategoryBenefitsUsingQuoteIds:'categories-benefits',
  getAgeBandCensusDetails:'get_age_band_census_details_based_on_category',
  excelConfig:'excel-config',
  nationality:'nationality',
  emirates:'get_emirates',
  uploadAllDocuments:'document-template',
  gettingCustomerAccepted:'document-template-customer-accept',
  distributorSelectedQuote:'quote-selection',
  changePassword:'change_password',
  getAllCredential:'get-all-credentials',
  getRoles:'roles',
  getRolesModules:'role-module',
  createCredentials:'create-credentials',
  updateCredentials:'update-credentials',
  deleteCredentials:'delete-credentials',
  getAllPermissions:'auth/get-permissions',
  getAllInsurerPremiumAndQuotesCount:'all-insurer',
  searchAllInsurerCOmpany:'search-insurer-company',
  getAllModules:'get-all-modules',
  getAllInsurerBrokerCompanyDetails:'insurer-broker-company',
  getAllInsurerBrokerCompanyAgentDetails:'insurer-broker-company-users',
  uploadCustomerAcceptedDocuments:'update-customer-documents'
};
