import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from 'axios';
import { ConfigManager } from 'src/app/api-config/configmanager';
import { BaseInterceptor } from 'src/app/shared/shared/interceptors/base.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  //url = ConfigManager.config.api.url;
  url=environment.apiUrl;
  private axios: AxiosInstance;
  //url ="http://localhost:3000/"
  constructor(private baseInterceptor: BaseInterceptor) {   
    this.axios = axios.create({
      baseURL: environment.apiUrl,
    });
    baseInterceptor.interceptor(this.axios);
  } 

  async login(login) {
    try {
      let result: any =await this.axios.post(`${this.url}auth/login`, login);
      return result
    } catch (error) {
      console.error('Error Signing Up')
      throw error
    }
  }
}
