import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import axios, { AxiosInstance } from "axios";
import { ToastrService } from "ngx-toastr";
import { SessionService } from "src/app/session-service/session.service";
import { environment } from "src/environments/environment";
import * as XLSX from "xlsx";
import { BaseInterceptor } from "../shared/shared/interceptors/base.interceptor";
import { TOKEN } from "src/assets/data-variable/session-variables";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  revisedPremium:any;
  secretKeyConfig={ };
  tokenFromUI = "93389a26699r89k3";
  encrypted;
  isCaptchaValid: boolean;

  private axios: AxiosInstance;
  constructor(private router:Router,private sessionStorage:SessionService,  private toaster: ToastrService,private baseInterceptor: BaseInterceptor) {
    this.axios = axios.create({
      baseURL: environment.apiUrl,
    });
    baseInterceptor.interceptor(this.axios);
  }

  getHeaders() {
    let token = this.sessionStorage.get(TOKEN);
    let clientCode = this.sessionStorage.get('clientCode');
    return this.secretKeyConfig = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': "bearer"+ " " + token,
        'client_code':clientCode,
        'insurer_company_code':this.sessionStorage.get('companyMasterId')
      }
    }
  }

  async getApiCall(url: string, params: any): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl + url;
      let response = await this.axios.get(urlStr);
      return response["data"];
    } catch (exception:any) {
            throw exception;
    }
  }

  async postApiCall(url: string, params: any): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl +  url;
      let response = await this.axios.post(urlStr, params);
      return response['data'];
    } catch (exception: any) {
      throw exception;
    }
  }

  async putApiCall(url: string, params: any): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl + url;
      let response = await this.axios.put(urlStr, params);
      return response['data'];
    } catch (exception: any) {
      throw exception;
    }
  }

  async deleteApiCall(url: string): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl + url;
      let response = await this.axios.delete(urlStr);
      return response['data'];
    } catch (exception: any) {
      throw exception;
    }
  }

  exportDatas(datas, sheetName) {
    const sheet = XLSX.utils.json_to_sheet(datas);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, sheetName);
    XLSX.writeFile(book, `${sheetName}.xlsx`, { compression: true });
  }

  exportTwoSheetDatas(data1,data2, sheetName1,sheetName2,bookName) {
    const sheet1 = XLSX.utils.json_to_sheet(data1);
    const sheet2 = XLSX.utils.json_to_sheet(data2);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet1, sheetName1);
    XLSX.utils.book_append_sheet(book, sheet2, sheetName2);
    XLSX.writeFile(book, `${bookName}.xlsx`, { compression: true });
  }

  encryptUsingAES256(req) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      req, _key, {
        keySize: 32,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }

  async validateCaptcha(captchaCode,loginForm) {
    this.isCaptchaValid = false;
    if (loginForm.value.captchaText.toLowerCase() === captchaCode.toLowerCase()) {
      // Captcha matched, proceed with the login logic
      this.isCaptchaValid = true;
      // Add your login logic here
    } else {
      // Captcha did not match
      this.isCaptchaValid = false;
    }
    return this.isCaptchaValid;
  }

  async generateCaptcha(length: number){
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    console.log(result)
    return result;
  }
}
