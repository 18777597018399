import { Injectable } from "@angular/core";
import { SessionService } from "../session-service/session.service";

@Injectable({
  providedIn: "root",
})
export class PermissionServiceService {
  userPermissions: string[] = ["manage-documentations", "company-configuration"]; // Array to store user's permissions
  constructor(private sessionService: SessionService) {}

  setUserPermissions(permissions: string[]) {
    this.userPermissions = permissions;
  }

  hasPermission(permission: string): boolean {
    this.userPermissions = JSON.parse(this.sessionService.get("permissions"));
    console.log(this.userPermissions.includes(permission));
    return this.userPermissions.includes(permission);
  }
}
