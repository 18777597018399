import * as moment from "moment";
import { AgeCalculator } from "./age-calculation-interface";

export class SpecificAgeCalculation implements AgeCalculator {
  calculateAge(dob, params) {
    let currentDate = params.policyEffectiveDate;
    let transformedCurrentDate = moment(currentDate).format("DD-MMM-YYYY");
    var todayDate = moment(transformedCurrentDate, "DD-MMM-YYYY");
    var b = moment(dob, "DD-MMM-YYYY");
    var years = todayDate.diff(b, "year");
    b.add(years, "years");
    var months = todayDate.diff(b, "months");
    b.add(months, "months");
    var days = todayDate.diff(b, "days");
    b.add(days, "days");
    let age;
    if (months >= 6 && days == 0) {
      age = years;
    } else if (months >= 6 && days > 0) {
      age = years + 1;
    } else {
      age = years;
    }
    return age;
  }

  calculateAgeLogic(birthDate, currentDate) {
    // Calculate the full years difference
    let yearsDifference = currentDate.getFullYear() - birthDate.getFullYear();

    let dayDifference = this.datedif_yd(birthDate, currentDate);
    if (currentDate.getMonth() <= birthDate.getMonth()) {
      if (currentDate.getMonth() < birthDate.getMonth() || currentDate.getDate() < birthDate.getDate()) {
        yearsDifference--;
        dayDifference--;
      }
    }
    // If the day difference is more than half a year, increment the age by 1
    if (dayDifference > 365 / 2) {
      yearsDifference++;
    }
    return yearsDifference;
  }

  datedif_yd(start, end) {
    // // Parse the dates
    // const start = new Date(startDate);
    // const end = new Date(endDate);

    // Ensure the dates are valid
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error("Invalid date format");
    }

    // Adjust the years to be the same for both dates
    start.setFullYear(end.getFullYear());

    // If the adjusted start date is after the end date, subtract one year
    if (start > end) {
      start.setFullYear(start.getFullYear() - 1);
    }

    // Calculate the difference in days
    const diffInMillis = end - start;
    const diffInDays = diffInMillis / (1000 * 60 * 60 * 24);

    return diffInDays;
  }

  calculateAgeForAxa(dob, params) {
    let convertedDob = moment(dob).format("YYYY-MM-DD");
    let converedCurrentDate = moment(params.policyEffectiveDate).format("YYYY-MM-DD");
    let birthDate = new Date(convertedDob);
    let currentDate = new Date(converedCurrentDate);
    let age = this.calculateAgeLogic(birthDate, currentDate);
    return age;
  }

  calculateAgeForTE(dob) {
    let currentDate = new Date();
    let transformedCurrentDate  = moment(currentDate).format("DD-MMM-YYYY");
    var todayDate = moment(transformedCurrentDate, "DD-MMM-YYYY");
    var b = moment(dob, "DD-MMM-YYYY");
    var years = todayDate.diff(b, "year");
    b.add(years, "years");
    var months = todayDate.diff(b, "months");
    b.add(months, "months");
    var days = todayDate.diff(b, "days");
    b.add(days, "days");
    let age = (months == 6 && days == 0) || months < 6 ? years : years + 1;
    return age;
  }

  calculateAgeForMedgulf(dob,params) {
    let converedCurrentDate = moment(params.policyEffectiveDate).format("DD-MMM-YYYY");
    var todayDate = moment(converedCurrentDate, "DD-MMM-YYYY");

    var b = moment(dob, "DD-MMM-YYYY");
    var years = todayDate.diff(b, "year");
    b.add(years, "years");
    var months = todayDate.diff(b, "months");
    b.add(months, "months");
    var days = todayDate.diff(b, "days");
    b.add(days, "days");
    let age = (months > 0 || days > 0 || days == 0 || months == 0) ? years + 1 : years;
    return age;
  }


  parseBirthDate(birthDateStr: string): Date {
    const [day, month, year] = birthDateStr.split("-");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = monthNames.indexOf(month);
    return new Date(parseInt(year), monthIndex, parseInt(day));
}

parseCurrentDate(currentDateStr: string): Date {
    const [year, month, day] = currentDateStr.split("-");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

calculateAgeForMedgulfNexrcare(birthDateStr: string, currentDateStr: string): number {
    const birthDate = this.parseBirthDate(birthDateStr);
    const currentDate = this.parseCurrentDate(currentDateStr);

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference = currentDate.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}


}
