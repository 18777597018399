<div>

  <div class="row">
    <div class="col-7 mt-5">
      <img src="assets/icons/reset_password.png" class="password-illustartion d-flex align-items-center">
    </div>
    <div class="col-4 mt-5">
       <div class="fm-form-parent-header mt-3">
    SME Group Medical
  </div>
      <app-change-password [hideCloseIcon] = "'hide'"></app-change-password>

    </div>
  </div>
</div>
