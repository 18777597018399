import { AgeCalculation } from "./comman-age-calculation";
import { SpecificAgeCalculation } from "./specific-age-calculation";

export class FactoryService {
  AgeCalculator(dob: any,params:any) {
    let age;
    if (params.insurerUrl === "aiaw") {
      age = new SpecificAgeCalculation();
      return age.calculateAge(dob,params);
    }else if(params.insurerUrl == "newton"){
      age = new SpecificAgeCalculation();
      return age.calculateAgeForAxa(dob,params);
    }else if(params.insurerUrl == "takafulemarat"){
      age = new SpecificAgeCalculation();
      return age.calculateAgeForTE(dob);
    }else if (params.insurerUrl === "medgulf-insurer" ) {
     const tpa =  sessionStorage.getItem('tpa');
     if(tpa && tpa.trim().toLowerCase()==="nextcare"){
     const ageData = new SpecificAgeCalculation();
      return ageData.calculateAgeForMedgulfNexrcare(dob, params.policyEffectiveDate)
     }
      age = new SpecificAgeCalculation();
      return age.calculateAgeForMedgulf(dob,params);
    }else {
      age = new AgeCalculation();
      return age.calculateAge(dob);
    }
  }
}
