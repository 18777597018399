import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api-service/api.service';
import { CommonApiService } from 'src/app/services/common-api-modules/common-api.service';
import { SessionService } from 'src/app/session-service/session.service';
import { API_ENDPOINTS_DISTRIBUTOR } from 'src/assets/data-variable/api-constant';
// import { OmanInsuranceService } from '../service/oman-insurance.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  matchPassword = false;
  @Input() hideCloseIcon:any = 'show';
  show = false;
  newPasswordShow=false;
  confirmPasswordShow=false;
  changePasswordForm: FormGroup;
  submitted: boolean = false;
  insurerUrl: any;
  role: any;
  userId: any;
  constructor(
    private toster:ToastrService,
    private router:Router,  public fb: FormBuilder,  private modalService: NgbModal,private apiService:ApiService,private sessionService:SessionService,private commonApiService : CommonApiService) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      current_password: ['',[Validators.required]],
      new_password: ['',[Validators.required]],
      confirm_password: ['',[Validators.required]],
    });

     // Set up value changes to validate in real-time

    this.changePasswordForm.get('new_password')?.valueChanges.subscribe(() => {
      this.validateNewPassword();
      this.validateConfirmPassword();
    });

    this.changePasswordForm.get('confirm_password')?.valueChanges.subscribe(() => {
      this.validateConfirmPassword();
    });
  }

  getSessionData() {
    this.insurerUrl = this.sessionService.get("insurerURL");
    this.role = this.sessionService.get("role");
    this.userId = this.sessionService.get("user_id");
  }

  get formControls() {
    return this.changePasswordForm.controls
  }

  async confirmPassword(){
    try{
      this.getSessionData();
    this.submitted = true;
    if (!this.changePasswordForm.valid) {
      return;
    }
    if (this.changePasswordForm.value.new_password !== this.changePasswordForm.value.confirm_password) {
      this.matchPassword = true;
      return;
    }
    this.matchPassword = false;;
    const params = {
      userId : this.userId,
      oldPassword : this.changePasswordForm.value.current_password,
      newPassword : this.changePasswordForm.value.new_password,
    }
    let results = await this.commonApiService.changePassword(params)
    if(!results){
      this.toster.error("Something went wrong");
      return;
    }
    this.toster.success("Password updated successfully")
    if(this.role == 'reinsurer'){
    let reinsurerShortForm = this.sessionService.get("reinsurerShortForm");
      this.router.navigateByUrl(`${reinsurerShortForm}/login`);
    }else{
      this.router.navigateByUrl(`/${this.insurerUrl}/login`);
    }
    this.modalService.dismissAll()

  }catch(error){
    if(error.response.status == 400){
      this.toster.error(error.response.data.message)
      return
    }else if(error.response.status == 401){
      this.toster.error(error.response.data.message)
      return
    }
  }
  }
  validatePassword(password: string): boolean {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }
  validateCurrent_NewPassword(newPassword,currentPassword) {
    if(newPassword == currentPassword) {
      return true
    } else{
      return false
    }
    
  }

  validateNewPassword() {
    const newPassword = this.formControls['new_password'].value;
    const currentPassword = this.formControls['current_password'].value;
    if (newPassword && !this.validatePassword(newPassword)) {
      this.formControls['new_password'].setErrors({ 'pattern': true });
    } else if (newPassword && this.validateCurrent_NewPassword(newPassword,currentPassword)) {
      this.formControls['new_password'].setErrors({ 'compareOldNew': true });
    } else {
      this.formControls['new_password'].setErrors(null);  // Clear errors
    }
  }
  
  validateConfirmPassword() {
    const confirmPassword = this.formControls['confirm_password'].value;
    if (confirmPassword && confirmPassword !== this.formControls['new_password'].value) {
      this.matchPassword = true;
      this.formControls['confirm_password'].setErrors({ 'match': true });
    } else {
      this.matchPassword = false;
      this.formControls['confirm_password'].setErrors(null);  // Clear errors
    }
  }
  closePasswordPopup(){
    this.modalService.dismissAll()
  }
}
