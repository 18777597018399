import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor() { }
  
  set(key:string,value:string){
    sessionStorage.setItem(key, value);
  }

  get(key:string):any{
    return sessionStorage.getItem(key);
  }

  remove(key:string):any{
    return sessionStorage.removeItem(key);
  }
}

