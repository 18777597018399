<div class="container-fluid py-5" style="height: 100vh;">
    <div class="">
        <div class="row">
            <div class="home-logo "><img class="home-image" alt="homeInsurance-image" src="assets/icons/aura-logos.png"></div>
            <div class="mt-2">
                <div class="col-5 p-0 parent-login-wrapper position-absolute">
                    <div class="login-wrapper  py-5">
                        <div class="row">
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <div class=""><img src="assets/icons/waving hand.png" alt="homeInsurance-hand">
                                    </div>
                                </div>
                                <div class="align-self-center ps-2">
                                    <div class="semi-bold welcome">Welcome Back</div>
                                    <div class="regular userDetails">Please enter your details</div>
                                </div>
                            </div>
                        </div>
                        <form class="mt-5" [formGroup]="loginForm" (ngSubmit)="login()">
                            <div class="user-feild">
                                <label class="light">Email Id</label>
                                <div>
                                    <input type="text" class="userIdTextbox w-100 p-1" formControlName="email_id" [ngClass]="{ 'is-invalid': submitted && formControls['email_id']['errors']}">
                                    <div *ngIf="submitted && formControls['email_id']['errors']" class="invalid-feedback">
                                        <div class="invalid-feedback d-block" *ngIf="formControls['email_id']['errors']['required']">
                                          Email is required</div>
                                          <div class="invalid-feedback d-block"*ngIf="formControls['email_id']['errors']['email']">Email must be a valid email address</div>
                                      </div>
                                </div>
                            </div>
                            <div class="password-feild">
                                <label class="light">Password</label>
                                <div class="position-relative"><input [type]="show?'text':'password'"  class="PasswordTextbox w-100 p-1" formControlName="password"  [ngClass]="{ 'is-invalid': submitted && formControls['password']['errors'] }">
                                    <img src="assets/icons/password-show.svg" (click)="show=!show" aria-hidden="true" *ngIf="show" class="inputbox">
                                    <img src="assets/icons/password-hide.svg"  (click)="show=!show" aria-hidden="true" *ngIf="!show" class="inputbox">
             
                                    <div *ngIf="submitted && formControls['password']['errors']" class="invalid-feedback">
                                        <div class="invalid-feedback d-block" *ngIf="formControls['password']['errors']['required']"> Password is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="forgot-password regular">Forgot Password?</div>

                            <div class="login-button-parent">
                                <div class="">
                                    <button class="login-button semi-bold" type="submit">Login</button>
                                </div>
                            </div>
                        </form>
                        <div class="or text-center medium">
                            <div> Or</div>
                        </div>
                        <div class="signuptext text-center">
                            <div class="semi-bold"> Sign up</div>
                        </div>
                        <div class="createNewAccount text-center">
                            <div class="light"> To create new account</div>
                        </div>
                    </div>
                </div>
                <div class="col-7 p-0 align-items-center column-second">
                    <div class="">
                        <img src="assets/icons/login-illus.png" class="loginpageImage">
                    </div>

                </div>
            </div>
        </div>
        <div class=" d-flex justify-content-end me-4">
            <div class="align-items-end helpLine semi-bold">
                <img src="assets/icons/need-help.png" class="phone-icon ms-4"> 8766755457
                <div class="regular">Do you need our help?</div>
            </div>
        </div>
    </div>

</div>